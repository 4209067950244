import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadGlobalClubs } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import Button from '../../components/Button';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import icon from '../../static/icons';
import notify from '../../utils/notify';
import { GlobalAccountFields as Fields } from '../account/types';

interface Props {
  data: GlobalAccountSchema;
  disabled: boolean;
  onSubmit: () => Promise<void>;
}

const GlobalAccountDetailClubs = (props: Props) => {
  const { data, disabled, onSubmit } = props;

  const { room, clubs, stopped_clubs } = data;

  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<Fields>();

  const clubIds = watch('club_ids');
  const stoppedClubIds = watch('stopped_club_ids');

  return (
    <PageAddon.QuickField.Group>
      {clubs.map((item) => {
        const stopped = stopped_clubs.map((el) => el.id).includes(item.id);

        return (
          <PageAddon.QuickField
            key={item.id}
            danger={stopped}
            title={item.name}
            subTitle={item.code}
            onDelete={() => {
              setValue(
                'club_ids',
                clubIds.filter((el) => el.value !== item.id)
              );

              onSubmit();
            }}
          >
            <Tooltip label={stopped ? t('common.start') : t('common.stop')}>
              <Button
                size="extra-small"
                variant="light"
                icon={stopped ? icon('playCircle', 16) : icon('pauseCircle', 16)}
                onClick={() => {
                  if (stopped) {
                    setValue(
                      'stopped_club_ids',
                      stoppedClubIds.filter((el) => el !== item.id)
                    );
                  } else {
                    setValue('stopped_club_ids', [...stoppedClubIds, item.id]);
                  }

                  onSubmit();

                  notify('success', {
                    title: stopped
                      ? t('sentences.club_has_been_removed_from_stop_list')
                      : t('sentences.club_has_been_added_to_stop_list'),
                  });
                }}
              />
            </Tooltip>
          </PageAddon.QuickField>
        );
      })}
      <Select.Async
        onLoad={async (args) => {
          const response = await loadGlobalClubs({
            ...args,
            poker_room_in: `${room}`,
          });

          const options = response.items.map((item) => ({
            value: item.id,
            label: item.name,
            caption: item.code,
          }));

          return options;
        }}
      >
        {({ options, ...selectAsyncProps }) => (
          <Select.Quick
            name="club_ids"
            disabled={disabled}
            options={options.filter((item) => !clubIds.map((el) => el.value).includes(item.value))}
            onChange={(value) => {
              setValue('club_ids', value ? [...clubIds, value] : []);

              onSubmit();
            }}
            {...selectAsyncProps}
          />
        )}
      </Select.Async>
    </PageAddon.QuickField.Group>
  );
};

export default GlobalAccountDetailClubs;
