import { useTranslation } from 'react-i18next';
import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import { STATUS } from '../../../api/schemas/channelSchema';
import PageAddon from '../../../components/PageAddon';

interface Props {
  data: GlobalAccountSchema;
}

const GlobalAccountChannels = (props: Props) => {
  const { data } = props;

  const { channels } = data;

  const { t } = useTranslation();

  return (
    <PageAddon.Field label={t('common.channels')}>
      <PageAddon.QuickField.Group>
        {channels.length
          ? channels.map((item) => (
              <PageAddon.QuickField
                key={item.id}
                title={item.host}
                danger={item.status === STATUS.UNKNOWN || item.status === STATUS.INACTIVE}
              />
            ))
          : '—'}
      </PageAddon.QuickField.Group>
    </PageAddon.Field>
  );
};

export default GlobalAccountChannels;
