import { FloatingOverlay } from '@floating-ui/react';
import clsx from 'clsx';
import { useState } from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import icon from '../../static/icons';
import Button from '../Button';
import { usePageContext } from '../Page';
import PageAddonCircles from './PageAddonCircles';
import PageAddonCode from './PageAddonCode';
import PageAddonCollapse from './PageAddonCollapse';
import PageAddonCollapseGroup from './PageAddonCollapseGroup';
import { PageAddonProvider, PageAddonContextConsumer } from './PageAddonContext';
import PageAddonControls from './PageAddonControls';
import PageAddonCopy from './PageAddonCopy';
import PageAddonDangerTitle from './PageAddonDangerTitle';
import PageAddonDetailPlaceholder from './PageAddonDetailPlaceholder';
import PageAddonDynamicFields from './PageAddonDynamicFields';
import PageAddonError from './PageAddonError';
import PageAddonExtraFields from './PageAddonExtraFields';
import PageAddonField from './PageAddonField';
import PageAddonFields from './PageAddonFields';
import PageAddonFilter from './PageAddonFilter';
import PageAddonGroup from './PageAddonGroup';
import stylesPageAddonGroup from './PageAddonGroup/styles.module.scss';
import PageAddonHeading from './PageAddonHeading';
import PageAddonId from './PageAddonId';
import PageAddonLoading from './PageAddonLoading';
import PageAddonMedia from './PageAddonMedia';
import PageAddonQuickField from './PageAddonQuickField';
import PageAddonSuccess from './PageAddonSuccess';
import PageAddonTabs from './PageAddonTabs';
import PageAddonTags from './PageAddonTags';
import PageAddonText from './PageAddonText';
import PageAddonTitleTabs from './PageAddonTitleTabs';
import PageAddonToggler from './PageAddonToggler';
import styles from './styles.module.scss';
import { Props } from './types';

const PageAddon = (props: Props) => {
  const {
    children,
    title,
    icons: titleIcons,
    disabled,
    isFilter,
    tabs,
    className: customClassName,
    onClose,
  } = props;

  const { isPhone, isTablet } = useBreakpoints();
  const { hasTabs } = usePageContext();

  const getDefaultTabIndex = () => {
    if (tabs) {
      const defaultTab = tabs.findIndex((tab) => tab.default);

      return defaultTab === -1 ? 0 : defaultTab;
    }

    return 0;
  };

  const [tabIndex, setTabIndex] = useState(getDefaultTabIndex);

  const className = clsx(styles.container, {
    [styles.filter]: isFilter,
    [styles.withOffset]: isTablet && hasTabs,
    ...(customClassName && { [customClassName]: true }),
  });

  const content = (
    <>
      <div data-title="true" className={clsx(styles.title, tabs && styles.withTabs)}>
        <div>
          {title}
          {titleIcons && <div className={styles.icons}>{titleIcons}</div>}
        </div>
        {!isFilter && (
          <Button
            size="small"
            variant="transparent"
            icon={icon('cross', 16)}
            disabled={disabled}
            onClick={onClose}
            className={styles.closeButton}
          />
        )}
      </div>
      {tabs && (
        <PageAddonTitleTabs
          tabs={tabs}
          tabIndex={tabIndex}
          onChange={(index) => setTabIndex(index)}
        />
      )}
      <div className={styles.content} data-content>
        {tabs && <PageAddonTabs tabs={tabs} tabIndex={tabIndex} />}
        {children}
      </div>
    </>
  );

  return (
    <PageAddonProvider>
      {isFilter && <div className={styles.spacer} />}
      <PageAddonContextConsumer>
        {({ extra }) =>
          isPhone ? (
            <FloatingOverlay lockScroll className={className}>
              {extra}
              {content}
            </FloatingOverlay>
          ) : (
            <div className={stylesPageAddonGroup.container}>
              {extra}
              <div className={className}>{content}</div>
            </div>
          )
        }
      </PageAddonContextConsumer>
    </PageAddonProvider>
  );
};

PageAddon.Circles = PageAddonCircles;
PageAddon.Code = PageAddonCode;
PageAddon.Collapse = PageAddonCollapse;
PageAddon.CollapseGroup = PageAddonCollapseGroup;
PageAddon.Controls = PageAddonControls;
PageAddon.Copy = PageAddonCopy;
PageAddon.DangerTitle = PageAddonDangerTitle;
PageAddon.DetailPlaceholder = PageAddonDetailPlaceholder;
PageAddon.DynamicFields = PageAddonDynamicFields;
PageAddon.Error = PageAddonError;
PageAddon.ExtraFields = PageAddonExtraFields;
PageAddon.Field = PageAddonField;
PageAddon.Fields = PageAddonFields;
PageAddon.Filter = PageAddonFilter;
PageAddon.Group = PageAddonGroup;
PageAddon.Heading = PageAddonHeading;
PageAddon.Id = PageAddonId;
PageAddon.Loading = PageAddonLoading;
PageAddon.Media = PageAddonMedia;
PageAddon.QuickField = PageAddonQuickField;
PageAddon.Success = PageAddonSuccess;
PageAddon.Tags = PageAddonTags;
PageAddon.Text = PageAddonText;
PageAddon.Toggler = PageAddonToggler;

export default PageAddon;
