import { z } from 'zod';
import workspaceDealSchema from '../deal/workspaceDealSchema';
import { identityCommonSchema } from '../identity';
import metricsSchema from '../metricsSchema';
import workspaceTagSchema from '../workspaceTagSchema';
import accountCommonIdentitySchema from './accountCommonIdentitySchema';
import { accountCommonSchema, accountCommonFilterValuesSchema } from '.';

const workspaceAccountPasswordSchema = z.string();
const workspaceAccountVpipSchema = z.number();

const workspaceAccountSchema = accountCommonSchema.extend({
  identity: accountCommonIdentitySchema, // cyclic
  login: z.string(),
  tags: z.array(workspaceTagSchema.pick({ id: true, name: true, color: true })),
  user: accountCommonSchema.shape.user.or(z.null()),
  vpip: workspaceAccountVpipSchema.or(z.null()),
  deals: z.array(workspaceDealSchema),
});

export const workspaceAccountFilterValuesSchema = accountCommonFilterValuesSchema.extend({
  tag: z.array(
    workspaceAccountSchema.shape.tags.element.pick({ id: true, name: true, color: true })
  ),
  identity: z.array(z.object({ id: identityCommonSchema.shape.id, name: z.string() })),
  hands_played: z.object({
    min: metricsSchema.shape.hands_played,
    max: metricsSchema.shape.hands_played,
  }),
  net_win_sum: z.object({
    min: metricsSchema.shape.net_win_sum,
    max: metricsSchema.shape.net_win_sum,
  }),
  win_rate_bb: z.object({
    min: metricsSchema.shape.win_rate_bb,
    max: metricsSchema.shape.win_rate_bb,
  }),
  vpip: z.object({
    min: workspaceAccountVpipSchema,
    max: workspaceAccountVpipSchema,
  }),
});

export type WorkspaceAccountPasswordSchema = z.infer<typeof workspaceAccountPasswordSchema>;
export type WorkspaceAccountSchema = z.infer<typeof workspaceAccountSchema>;

export default workspaceAccountSchema;
