import copyToClipboard from 'copy-to-clipboard';
import i18n from '../i18n';
import notify from './notify';

type Value = string | number;

type CopyFn = (value: Value) => void;

export const copy = (value: Value, label: string) => {
  const title = i18n.t('sentences.success_copy', { label });

  copyToClipboard(String(value));

  notify('success', { title });
};

export const copyId: CopyFn = (value) => copy(value, i18n.t('common.id'));
export const copyLogin: CopyFn = (value) => copy(value, i18n.t('common.login'));
export const copyUsername: CopyFn = (value) => copy(value, i18n.t('common.username'));
export const copyPassword: CopyFn = (value) => copy(value, i18n.t('common.password'));
export const copyPhone: CopyFn = (value) => copy(value, i18n.t('common.phone'));
export const copyAddress: CopyFn = (value) => copy(value, i18n.t('common.address'));
export const copyHost: CopyFn = (value) => copy(value, i18n.t('common.host'));
export const copyClubId: CopyFn = (value) => copy(value, i18n.t('common.id'));
export const copyClubName: CopyFn = (value) => copy(value, i18n.t('common.club_name'));
export const copyClubCode: CopyFn = (value) => copy(value, i18n.t('common.club_code'));
export const copyHandHistoryId: CopyFn = (value) => copy(value, i18n.t('common.hand_history_id'));
export const copyLeagueId: CopyFn = (value) => copy(value, i18n.t('common.league_id'));
export const copyIp: CopyFn = (value) => copy(value, i18n.t('common.ip'));
export const copyHeader: CopyFn = (value) =>
  copy(`Authorization: Maintenance ${value}`, i18n.t('common.header'));
export const copyAffilateReference: CopyFn = (value) =>
  copy(value, i18n.t('common.affiliate_reference'));
export const copyPid: CopyFn = (value) => copy(value, i18n.t('common.pid'));
export const copyNickname: CopyFn = (value) => copy(value, i18n.t('common.nickname'));
export const copyText: CopyFn = (value) => copy(value, i18n.t('common.text'));
