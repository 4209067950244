import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { pick } from 'remeda';
import { updateGlobalAccountRoomConfig } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { AccountRoomConfigSchema } from '../../api/schemas/accountRoomConfigSchema';
import { TRAINER_POKER_ROOM } from '../../api/schemas/trainerPokerRoomSchema';
import Input from '../../components/Input';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { required, max, handleError } from '../../utils/form';
import { numbers } from '../../utils/validations';
import {
  backgroundsOptions,
  cardsBackOptions,
  deckTypesOptions,
  feltColorsOptions,
  getDefaultValues,
  getPayload,
  roomsFields,
  tableShapesOptions,
} from '../account/AccountRoomConfig/helpers';
import { Fields } from '../account/AccountRoomConfig/types';
import { booleanOptions } from '../account/helpers';
import { can } from '../permission/helpers';

interface Props {
  account: GlobalAccountSchema;
  data: AccountRoomConfigSchema;
}

const GlobalAccountDetailRoomConfigTab = (props: Props) => {
  const { account, data } = props;

  const { id: accountId, room } = account;

  const { t } = useTranslation();

  const disabled = !can.account.roomConfigCreateUpdate;

  const form = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: getDefaultValues({ data }),
  });

  const { control, handleSubmit, setError } = form;

  const onSubmit = async (values: Fields) => {
    try {
      await updateGlobalAccountRoomConfig({
        accountId,
        payload: pick(getPayload({ values }), roomsFields[room]),
      });
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const commonProps = { control, disabled, onSubmit: handleSubmit(onSubmit) };

  const balanceCheck = (
    <PageAddon.Field label={t('common.balance_check')}>
      <Select.Quick name="balance_check" options={booleanOptions} {...commonProps} />
    </PageAddon.Field>
  );

  const autoplay = (
    <PageAddon.Field label={t('common.autoplay')}>
      <Select.Quick name="autoplay" options={booleanOptions} {...commonProps} />
    </PageAddon.Field>
  );

  const showCards = (
    <PageAddon.Field label={t('common.show_cards')}>
      <Select.Quick name="show_cards" options={booleanOptions} {...commonProps} />
    </PageAddon.Field>
  );

  return (
    <FormProvider {...form}>
      <PageAddon.Fields>
        {room === TRAINER_POKER_ROOM.PARTYPOKER && (
          <>
            <PageAddon.Field label={t('common.table_shape')}>
              <Select.Quick name="TableShape" options={tableShapesOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.felt_color')}>
              <Select.Quick name="FeltColor" options={feltColorsOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.background')}>
              <Select.Quick name="Background" options={backgroundsOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.deck_type')}>
              <Select.Quick name="DeckType" options={deckTypesOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.card_back')}>
              <Select.Quick name="CardBack" options={cardsBackOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.default_avatar')}>
              <Select.Quick name="IsDefaultAvatar" options={booleanOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('sentences.auto_buy_in_rebuy_cash_games')}>
              <Select.Quick
                name="AutoBuyinCashGamesRebuy"
                options={booleanOptions}
                {...commonProps}
              />
            </PageAddon.Field>
            <PageAddon.Field label={t('sentences.muck_losing_uncalled_hands')}>
              <Select.Quick
                name="MuckLosingUncalledHands"
                options={booleanOptions}
                {...commonProps}
              />
            </PageAddon.Field>
          </>
        )}
        {room === TRAINER_POKER_ROOM.PPPOKER && (
          <>
            <PageAddon.Field label={t('common.ev_chop_%')}>
              <Input.Quick
                name="ev_chop_percent"
                allow={numbers}
                rules={{ validate: { required, max: max(t('common.ev_chop_%'), 100) } }}
                {...commonProps}
              />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.rim_%')}>
              <Input.Quick
                name="rim_percent"
                allow={numbers}
                rules={{ validate: { required, max: max(t('common.rim_%'), 100) } }}
                {...commonProps}
              />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.ppsr')}>
              <Select.Quick name="ppsr" options={booleanOptions} {...commonProps} />
            </PageAddon.Field>
            {autoplay}
            {showCards}
          </>
        )}
        {room === TRAINER_POKER_ROOM.POKERRRR2 && (
          <>
            <PageAddon.Field label={t('common.rit_%')}>
              <Input.Quick
                name="rit_percent"
                allow={numbers}
                rules={{ validate: { required, max: max(t('common.rit_%'), 100) } }}
                {...commonProps}
              />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.dead_blind')}>
              <Select.Quick name="dead_blind" options={booleanOptions} {...commonProps} />
            </PageAddon.Field>
            {balanceCheck}
            <PageAddon.Field label={t('common.auto_muck')}>
              <Select.Quick name="auto_muck" options={booleanOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.timebank')}>
              <Select.Quick name="timebank" options={booleanOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.standart_stack_seat')}>
              <Select.Quick
                name="is_standard_stack_seat"
                options={booleanOptions}
                {...commonProps}
              />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.auto_rebuy')}>
              <Select.Quick name="auto_rebuy" options={booleanOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.tournament_addon')}>
              <Select.Quick name="tournament_addon" options={booleanOptions} {...commonProps} />
            </PageAddon.Field>
            <PageAddon.Field label={t('common.max_tournament_rebuy')}>
              <Input.Quick
                name="max_tournament_rebuy"
                allow={numbers}
                rules={{ validate: { required, max: max(t('common.max_tournament_rebuy'), 5) } }}
                {...commonProps}
              />
            </PageAddon.Field>
          </>
        )}
        {room === TRAINER_POKER_ROOM.RULER && (
          <>
            <PageAddon.Field label={t('common.nickname')}>
              <Input.Quick name="nickname" {...commonProps} />
            </PageAddon.Field>
            {balanceCheck}
          </>
        )}
        {room === TRAINER_POKER_ROOM.CLUBGG && (
          <>
            {autoplay}
            {showCards}
          </>
        )}
      </PageAddon.Fields>
    </FormProvider>
  );
};

export default GlobalAccountDetailRoomConfigTab;
