import { useTranslation } from 'react-i18next';
import { GlobalClubSchema } from '../../../api/schemas/globalClubSchema';
import PageAddon from '../../../components/PageAddon';
import Tag from '../../../components/Tag';
import Tooltip from '../../../components/Tooltip';
import { copyClubCode, copyClubName } from '../../../utils/clipboard';
import styles from './styles.module.scss';

type Clubs = Pick<GlobalClubSchema, 'id' | 'name' | 'code'>[];

interface Props {
  data: Clubs;
  stoppedClubs?: Clubs;
}

const ClubPageAddonTags = (props: Props) => {
  const { data, stoppedClubs = [] } = props;

  const { t } = useTranslation();

  if (!data.length) return <PageAddon.Tags>—</PageAddon.Tags>;

  return (
    <PageAddon.Tags>
      {data.map((item) => (
        <Tag
          key={item.id}
          danger={stoppedClubs.map((el) => el.id).includes(item.id)}
          caption={
            <Tag.Caption>
              <Tooltip label={t('common.copy_club_code')}>
                <button
                  type="button"
                  className={styles.button}
                  onClick={() => copyClubCode(item.code)}
                >
                  {item.code}
                </button>
              </Tooltip>
            </Tag.Caption>
          }
        >
          <Tooltip label={t('common.copy_club_name')}>
            <button type="button" onClick={() => copyClubName(item.name)} className={styles.button}>
              {item.name}
            </button>
          </Tooltip>
        </Tag>
      ))}
    </PageAddon.Tags>
  );
};

export default ClubPageAddonTags;
