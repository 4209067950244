import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateGlobalAccount } from '../../api';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import FadingText from '../../components/FadingText';
import Input from '../../components/Input';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Select from '../../components/Select';
import { formatAbsoluteDate } from '../../utils/date';
import { handleError } from '../../utils/form';
import { TRAINER_POKER_ROOM_LABELS, trainerGameTypeOptions } from '../../utils/trainer';
import AccountGeolocationDialog from '../account/AccountGeolocationDialog';
import { limitOptions, maxTableOptions, tableSizeOptions } from '../account/helpers';
import { GlobalAccountFields as Fields } from '../account/types';
import { can } from '../permission/helpers';
import { getTitle } from '../session/helpers';
import GlobalAccountChannels from './GlobalAccountChannels';
import GlobalAccountDetailBotSettings from './GlobalAccountDetailBotSettings';
import GlobalAccountDetailClubs from './GlobalAccountDetailClubs';
import { getDefaultValues, getPayload } from './helpers';

interface Props {
  data: GlobalAccountSchema;
  getUserRoute?: (args: { userId: GlobalUserSchema['id'] }) => string;
}

const GlobalAccountDetailGeneralTab = (props: Props) => {
  const { data, getUserRoute } = props;

  const {
    id,
    maintenance,
    room,
    user,
    email,
    affiliate_reference_number,
    next_session,
    last_online,
    last_played,
    created_on,
    modified_on,
  } = data;

  const { t } = useTranslation();

  const disabled = !can.account.createUpdate;

  const getUser = () => {
    if (!user) return '—';

    const content = user.username;

    if (can.user.read && getUserRoute) {
      return <Link to={getUserRoute({ userId: user.id })}>{content}</Link>;
    }

    return content;
  };

  const form = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: getDefaultValues({ data }),
  });

  const { control, handleSubmit, setError } = form;

  const onSubmit = async (values: Fields) => {
    try {
      await updateGlobalAccount({ accountId: data.id, payload: getPayload({ values }) });
    } catch (error) {
      handleError({ error, setError });
    }
  };

  const commonProps = { control, disabled, onSubmit: handleSubmit(onSubmit) };

  return (
    <FormProvider {...form}>
      <PageAddon.Fields>
        <PageAddon.Id id={id} />
        <PageAddon.Field label={t('common.workspace')}>
          <FadingText text={maintenance.name} tooltip={false} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.poker_room')}>
          {TRAINER_POKER_ROOM_LABELS[room]}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.clubs')}>
          <GlobalAccountDetailClubs
            data={data}
            disabled={disabled}
            onSubmit={() => commonProps.onSubmit()}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.game_types')}>
          <Select.Multi.Quick name="game_types" options={trainerGameTypeOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.limits')}>
          <Select.Multi.Quick name="comparative_limits" options={limitOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.table_size')}>
          <Select.Multi.Quick name="table_sizes" options={tableSizeOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.max_tables')}>
          <Select.Quick name="max_tables" options={maxTableOptions} {...commonProps} />
        </PageAddon.Field>
        <PageAddon.Field
          label={t('common.geolocation')}
          controls={<AccountGeolocationDialog quick onChange={() => commonProps.onSubmit()} />}
        >
          <Input.Quick name="geo" {...commonProps} />
        </PageAddon.Field>
        <GlobalAccountChannels data={data} />
        {can.account.emailRead && (
          <PageAddon.Field label={t('common.email')}>
            <FadingText text={email || '—'} />
          </PageAddon.Field>
        )}
        <PageAddon.Field label={t('common.bot_settings')}>
          <GlobalAccountDetailBotSettings
            data={data}
            disabled={disabled}
            onSubmit={() => commonProps.onSubmit()}
          />
        </PageAddon.Field>
        <PageAddon.Field label={t('common.assign_to')}>{getUser()}</PageAddon.Field>
        <PageAddon.Field.AffiliateReference value={affiliate_reference_number} />
        <PageAddon.Field label={t('common.next_session')}>
          {next_session ? getTitle({ start: next_session.start, end: next_session.end }) : '—'}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_online')}>
          {formatAbsoluteDate(last_online)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.last_played')}>
          {formatAbsoluteDate(last_played)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.modified')}>
          {formatAbsoluteDate(modified_on)}
        </PageAddon.Field>
        <PageAddon.Field label={t('common.created')}>
          {formatAbsoluteDate(created_on)}
        </PageAddon.Field>
      </PageAddon.Fields>
    </FormProvider>
  );
};

export default GlobalAccountDetailGeneralTab;
