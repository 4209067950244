import clsx from 'clsx';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import icon from '../../../static/icons';
import { copyText } from '../../../utils/clipboard';
import Button from '../../Button';
import Confirm from '../../Confirm';
import Tooltip from '../../Tooltip';
import PageAddonQuickFieldGroup from '../PageAddonQuickFieldGroup';
import styles from './styles.module.scss';

interface Props {
  title: string;
  onDelete?: () => void;
  subTitle?: string;
  danger?: boolean;
  children?: ReactNode;
}

const PageAddonQuickField = (props: Props) => {
  const { title, subTitle, danger, children, onDelete } = props;

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div>
        <button
          type="button"
          onClick={() => copyText(title)}
          className={clsx(
            styles.button,
            styles.title,
            subTitle && styles.hasSubTitle,
            danger && styles.danger
          )}
        >
          {title}
        </button>
        {subTitle && (
          <button
            type="button"
            onClick={() => copyText(subTitle)}
            className={clsx(styles.button, styles.subTitle)}
          >
            {subTitle}
          </button>
        )}
      </div>
      <div className={styles.actions}>
        {children}
        {onDelete && (
          <Tooltip label={t('common.delete')}>
            <Confirm
              onConfirm={onDelete}
              title={t('sentences.delete_this_record')}
              confirmText={t('common.delete')}
            >
              {({ confirming }) => (
                <Button
                  danger
                  focused={confirming}
                  size="extra-small"
                  variant="light"
                  icon={icon('trash', 16)}
                />
              )}
            </Confirm>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

PageAddonQuickField.Group = PageAddonQuickFieldGroup;

export default PageAddonQuickField;
