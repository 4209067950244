import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Input from '../../../components/Input';
import Map from '../../../components/Map';
import icon from '../../../static/icons';
import { getGeolocationAsString } from '../helpers';
import { CommonAccountFields } from '../types';
import styles from './styles.module.scss';

interface Props {
  onChange?: () => void;
  quick?: boolean;
}

const AccountGeolocationDialog = (props: Props) => {
  const { onChange, quick } = props;

  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<CommonAccountFields>();

  const { geo } = watch();

  const [lat, lng] = geo.split(',');

  return (
    <Dialog
      secondary
      label={({ referenceProps }) =>
        quick ? (
          <Button size="extra-small" variant="light" {...referenceProps} icon={icon('globe', 16)} />
        ) : (
          <Input.Button variant="bordered" {...referenceProps}>
            {icon('globe', 20)}
          </Input.Button>
        )
      }
      className={styles.dialog}
    >
      {({ close }) => (
        <>
          <Dialog.Top>
            <Dialog.Title>{t('common.geolocation')}</Dialog.Title>
          </Dialog.Top>
          <Map
            className={styles.map}
            onChange={(value) => {
              setValue('geo', getGeolocationAsString(value));

              if (onChange) onChange();
            }}
            {...(geo && { value: { lat: Number(lat), lng: Number(lng) } })}
          />
          <Dialog.Footer>
            <Button onClick={() => close()}>{t('common.apply')}</Button>
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  );
};

export default AccountGeolocationDialog;
