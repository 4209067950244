import clsx from 'clsx';
import { z } from 'zod';
import { copyId } from '../../utils/clipboard';
import { cutUUID } from '../../utils/data';
import styles from './styles.module.scss';

interface Props {
  variant: 'primary' | 'secondary';
  id: string;
}

const Id = (props: Props) => {
  const { variant, id } = props;

  const handleClick = () => copyId(id);

  const parsedId = z.string().uuid().safeParse(id);

  return (
    <button type="button" onClick={handleClick} className={clsx(styles.id, styles[variant])}>
      {parsedId.success ? cutUUID(id) : id}
    </button>
  );
};

export default Id;
