import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { GlobalAccountSchema } from '../../../api/schemas/account/globalAccountSchema';
import { GlobalUserSchema } from '../../../api/schemas/globalUserSchema';
import Button from '../../../components/Button';
import PageAddon from '../../../components/PageAddon';
import Tooltip from '../../../components/Tooltip';
import routes from '../../../config/routes';
import useURL from '../../../hooks/useURL';
import icon from '../../../static/icons';
import { addWhen } from '../../../utils';
import { copyNickname, copyPid } from '../../../utils/clipboard';
import AccountPilotButton from '../../account/AccountPilotButton';
import AccountPilotView from '../../account/AccountPilotView';
import AccountStatus from '../../account/AccountStatus';
import { getScheduleTooltip } from '../../account/helpers';
import { OnTransactionsOpen } from '../../account/types';
import { can } from '../../permission/helpers';
import GlobalAccountDetailBalanceTab from '../GlobalAccountDetailBalanceTab';
import GlobalAccountDetailDropdown from '../GlobalAccountDetailDropdown';
import GlobalAccountDetailGeneralTab from '../GlobalAccountDetailGeneralTab';
import GlobalAccountDetailRoomConfigTab from '../GlobalAccountDetailRoomConfigTab';
import GlobalAccountDetailStrategyProfileTab from '../GlobalAccountDetailStrategyProfileTab';
import GlobalAccountDetailTablesTab from '../GlobalAccountDetailTablesTab';
import GlobalAccountRoomConfigLoader from '../GlobalAccountRoomConfigLoader';
import { useGlobalAccountTablesQuery } from '../queries';
import styles from './styles.module.scss';

interface Props {
  data: GlobalAccountSchema;
  onClose: () => void;
  onTransactionsOpen?: OnTransactionsOpen;
  getUserRoute?: (args: { userId: GlobalUserSchema['id'] }) => string;
}

const GlobalAccountDetail = (props: Props) => {
  const { data, onClose, onTransactionsOpen, getUserRoute } = props;

  const { t } = useTranslation();
  const { navigate } = useURL();

  /* const { fingerprintAccountId } = urlParams; */

  const { id, mga_id, comparative_limits, display_name, pid, stage } = data;

  const { data: tables } = useGlobalAccountTablesQuery(data.id, can.lobby.read && data.is_online);

  const divider = <span className={styles.divider}>•</span>;

  return (
    <PageAddon.Group>
      <Outlet />
      {/* {fingerprintAccountId && (
        <GlobalAccountLoader accountId={fingerprintAccountId}>
          {(args) => (
            <GlobalAccountDetail
              data={args.data}
              onClose={() => setURLParams({ fingerprintAccountId: null })}
            />
          )}
        </GlobalAccountLoader>
      )} */}
      <PageAddon
        title={
          <div className={styles.title}>
            <span>{mga_id}</span>
            <div>
              {display_name && (
                <>
                  <span className={styles.field}>
                    <button
                      type="button"
                      onClick={() => copyNickname(display_name)}
                      className={clsx(styles.button, styles.fading)}
                    >
                      {display_name}
                    </button>
                  </span>
                  {divider}
                </>
              )}
              {pid && (
                <>
                  <span className={styles.field}>
                    <button type="button" onClick={() => copyPid(pid)} className={styles.button}>
                      {pid}
                    </button>
                  </span>
                  {divider}
                </>
              )}
              <span className={styles.field}>{stage.name}</span>
              {divider}
              <AccountStatus data={data} />
            </div>
          </div>
        }
        onClose={onClose}
        tabs={[
          {
            label: t('common.general'),
            content: <GlobalAccountDetailGeneralTab data={data} getUserRoute={getUserRoute} />,
          },
          {
            label: t('common.strategy'),
            content: <GlobalAccountDetailStrategyProfileTab account={data} />,
          },
          ...addWhen(
            {
              label: t('common.config'),
              content: (
                <GlobalAccountRoomConfigLoader accountId={data.id}>
                  {({ data: roomConfig }) => (
                    <GlobalAccountDetailRoomConfigTab account={data} data={roomConfig} />
                  )}
                </GlobalAccountRoomConfigLoader>
              ),
            },
            can.account.roomConfigRead
          ),
          ...addWhen(
            {
              label: t('common.balance'),
              content: (
                <GlobalAccountDetailBalanceTab
                  accountData={data}
                  onTransactionsOpen={onTransactionsOpen}
                />
              ),
            },
            can.account.balanceRead
          ),
          ...addWhen(
            {
              label: t('common.tables'),
              content: <GlobalAccountDetailTablesTab data={data} />,
              count: tables?.tables.length,
            },
            can.lobby.read
          ),
          /* {
            label: t('common.diagnostic'),
            content: <GlobalAccountDetailDiagnosticTab data={data} />,
          }, */
          /* ...addWhen(
            {
              label: t('common.fingerprint'),
              content: <GlobalAccountDetailFingerprintTab accountData={data} />,
            },
            can.account.fingerprintRead
          ), */
        ]}
      >
        <PageAddon.Controls>
          <PageAddon.Controls.Group>
            <AccountPilotView data={data} isDetail />
            <AccountPilotButton data={data} isDetail />
            <Tooltip
              label={getScheduleTooltip({ account: data }) || t('common.schedule')}
              hideOnMobile={can.session.read && Boolean(comparative_limits.length)}
            >
              <div>
                <Button
                  icon={icon('calendar', 16)}
                  variant="transparent"
                  onClick={() => navigate(routes.accountSchedule({ accountId: id }))}
                  data-testid="schedule"
                  disabled={!can.session.read || !comparative_limits.length}
                />
              </div>
            </Tooltip>
            <GlobalAccountDetailDropdown data={data} />
          </PageAddon.Controls.Group>
        </PageAddon.Controls>
      </PageAddon>
    </PageAddon.Group>
  );
};

export default GlobalAccountDetail;
