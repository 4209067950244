import { ReactNode } from 'react';
import PageAddonFieldAffiliateReference from './PageAddonFieldAffiliateReference';
import styles from './styles.module.scss';

interface Props {
  label: ReactNode;
  children: ReactNode;
  controls?: ReactNode;
}

const PageAddonField = (props: Props) => {
  const { label, children, controls } = props;

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.content}>{children}</div>
      {controls && <div className={styles.controls}>{controls}</div>}
    </div>
  );
};

PageAddonField.AffiliateReference = PageAddonFieldAffiliateReference;

export default PageAddonField;
