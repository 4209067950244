import { WorkspaceAccountSchema } from '../../api/schemas/account/workspaceAccountSchema';
import { COLUMNS, WorkspaceStageSchema } from '../../api/schemas/stage/workspaceStageSchema';
import Tag from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import i18n from '../../i18n';
import { convertCentsToDollars } from '../../utils/data';

export const COLUMNS_LABELS: Record<WorkspaceStageSchema['columns'][number], string> = {
  [COLUMNS.UNKNOWN]: i18n.t('common.unknown'),
  [COLUMNS.CREATED_ON]: i18n.t('common.created'),
  [COLUMNS.MODIFIED_ON]: i18n.t('common.modified'),
  [COLUMNS.ROOM]: i18n.t('common.poker_room'),
  [COLUMNS.CLUB_NAME]: i18n.t('common.club_name'),
  [COLUMNS.GAME_TYPES]: i18n.t('common.game_types'),
  [COLUMNS.LIMITS]: i18n.t('common.limits'),
  [COLUMNS.TABLE_SIZES]: i18n.t('common.table_sizes'),
  [COLUMNS.MAX_TABLES]: i18n.t('common.max_tables'),
  [COLUMNS.IS_ONLINE]: i18n.t('common.status'),
  [COLUMNS.PID]: i18n.t('common.pid'),
  [COLUMNS.GEO]: i18n.t('common.geolocation'),
  [COLUMNS.CHANNELS]: i18n.t('common.channels'),
  [COLUMNS.HANDS_PLAYED]: i18n.t('common.hands'),
  [COLUMNS.NET_WIN_SUM]: i18n.t('common.net_win'),
  [COLUMNS.WIN_RATE_BB]: i18n.t('common.bb/100'),
  [COLUMNS.TAGS]: i18n.t('common.tags'),
  [COLUMNS.BOT_SETTINGS]: i18n.t('common.bot_settings'),
  [COLUMNS.IDENTITY]: i18n.t('common.identity'),
  [COLUMNS.AFFILIATE_REFERENCE_NUMBER]: i18n.t('common.affiliate_reference'),
  [COLUMNS.LAST_ONLINE]: i18n.t('common.last_online'),
  [COLUMNS.PILOT_STATUS]: i18n.t('common.pilot'),
  [COLUMNS.LAST_PLAYED]: i18n.t('common.last_played'),
  [COLUMNS.USER]: i18n.t('common.assign_to'),
  [COLUMNS.CLUB_CODE]: i18n.t('common.club_code'),
  [COLUMNS.NEXT_SESSION]: i18n.t('common.next_session'),
  [COLUMNS.IDENTITY_BANK_ACCOUNTS]: i18n.t('common.bank_accounts'),
  [COLUMNS.IDENTITY_DATE_OF_BIRTH]: i18n.t('common.date_of_birth'),
  [COLUMNS.IDENTITY_DOCUMENTS]: i18n.t('common.documents'),
  [COLUMNS.IDENTITY_FULL_NAME]: i18n.t('common.full_name'),
  [COLUMNS.IDENTITY_GENDER]: i18n.t('common.gender'),
  [COLUMNS.IDENTITY_LOCATION]: i18n.t('common.location'),
  [COLUMNS.IDENTITY_ONLINE_WALLETS]: i18n.t('common.online_wallets'),
  [COLUMNS.IDENTITY_PHONES]: i18n.t('common.phones'),
  [COLUMNS.IDENTITY_PHOTOS]: i18n.t('common.photos'),
  [COLUMNS.IDENTITY_SOCIAL_NETWORKS]: i18n.t('common.social_networks'),
  [COLUMNS.TRAINER_VERSION]: i18n.t('common.trainer_version'),
  [COLUMNS.NOTES]: i18n.t('common.notes'),
  [COLUMNS.BALANCE_CLUB]: i18n.t('common.club_balance'),
  [COLUMNS.BALANCE_LOBBY]: i18n.t('common.lobby_balance'),
  [COLUMNS.DISPLAY_NAME]: i18n.t('common.nickname'),
  [COLUMNS.EMAIL]: i18n.t('common.email'),
  [COLUMNS.VPIP]: i18n.t('common.vpip'),
  [COLUMNS.DEALS]: i18n.t('common.deals'),
};

export const getChannelsValue = (data: WorkspaceAccountSchema['channels']) => {
  if (!data) throw new Error();

  return data.map((item) => ({
    value: item.id,
    label: item.host,
  }));
};

export const getAccountMetrics = (data: WorkspaceAccountSchema['metrics']) => {
  const { net_win_sum, win_rate_bb, ...rest } = data;

  return {
    net_win_sum: convertCentsToDollars(net_win_sum),
    win_rate_bb: Number(win_rate_bb.toFixed(2)),
    ...rest,
  };
};

export const getSortingLabel = (item: string) => {
  if (item === 'login') return i18n.t('common.login');

  const label = Object.entries(COLUMNS).find((el) => el[0].toLowerCase() === item);

  if (label) return COLUMNS_LABELS[label[1]];

  return i18n.t('common.unknown');
};

export const sortings = [
  'login',
  'club_name',
  'club_code',
  'created_on',
  'modified_on',
  'room',
  'max_tables',
  'pid',
  'hands_played',
  'net_win_sum',
  'win_rate_bb',
  'identity',
  'affiliate_reference_number',
  'last_online',
  'last_played',
  'pilot_status',
  'geo',
  'bot_settings',
  'user',
  'next_session',
  'notes',
  'display_name',
];

export const getSortingKey = (item: (typeof COLUMNS)[keyof typeof COLUMNS]) => {
  const key = Object.entries(COLUMNS).find((el) => el[1] === item);

  if (key && sortings.includes(key[0].toLowerCase())) return key[0].toLowerCase();

  return undefined;
};

export const renderVpip = (data: WorkspaceAccountSchema) => (data.vpip === null ? '—' : data.vpip);

export const getDeals = (data: WorkspaceAccountSchema) => {
  const { deals } = data;

  if (!deals.length) return '—';

  return deals.map((item) => (
    <Tooltip
      key={item.id}
      label={
        <Tooltip.FieldGroup>
          <Tooltip.Field label={i18n.t('common.agent')}>{item.agent.name}</Tooltip.Field>
          <Tooltip.Field label={i18n.t('common.club_name')}>{item.club.name}</Tooltip.Field>
          <Tooltip.Field label={i18n.t('common.club_id')}>{item.club.code}</Tooltip.Field>
        </Tooltip.FieldGroup>
      }
    >
      <Tag key={item.id}>
        <Tag.FadingText text={item.code} tooltipLabel={item.code} />
      </Tag>
    </Tooltip>
  ));
};
