import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import clsx from 'clsx';
import mapboxgl, { Map as MapType } from 'mapbox-gl';
import { ElementRef, useEffect, useRef, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import styles from './styles.module.scss';

interface Value {
  lat: number;
  lng: number;
}

mapboxgl.accessToken =
  'pk.eyJ1Ijoibm91c2RldiIsImEiOiJjbDgydHNybW8wOG83M3JxZHZjaDhnaXJuIn0.xxfR5kZ7xfM7acXF0W5Pxw';

interface Props {
  value?: Value;
  className?: string;
  onChange?: (value: Value) => void;
}

const Map = (props: Props) => {
  const { className, value, onChange } = props;

  const mapContainer = useRef<ElementRef<'div'>>(null);
  const map = useRef<MapType | null>(null);
  const marker = useRef(new mapboxgl.Marker());

  const [isMarkerOnMap, setIsMarkerOnMap] = useState(Boolean(value));

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        attributionControl: false,
        ...(value && { center: value, zoom: 16 }),
      });

      map.current.addControl(new mapboxgl.FullscreenControl());

      map.current.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken }), 'top-left');

      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        'top-right'
      );

      map.current.addControl(
        new mapboxgl.NavigationControl({
          showCompass: false,
        }),
        'bottom-right'
      );

      map.current.on('click', (event) => {
        const setLngLat = marker.current.setLngLat(event.lngLat);

        if (!isMarkerOnMap) {
          setLngLat.addTo(event.target);

          setIsMarkerOnMap(true);
        }

        if (onChange) onChange(event.lngLat);
      });
    }

    if (value && map.current) {
      marker.current.setLngLat(value).addTo(map.current);
    }
  }, [isMarkerOnMap, value, onChange]);

  return <div ref={mapContainer} className={clsx(styles.container, className)} />;
};

export default Map;
